<script>
import { usePerk } from '/~rec/composables/perk'
import { useRecProfile } from '/~rec/composables/profile'
import { useRoute } from 'vue-router/composables'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import DetailsPlaceholder from '/~rec/components/placeholder/placeholder-awards-perks-hi-details.vue'
import modal from '/~/core/mdl'
import PerkInfo from '/~rec/components/perk/perk-info.vue'
import PerkRedeemed from '/~rec/components/perk/perk-redeemed.vue'
import RecognitionTokens from '/~rec/components/recognition/recognition-tokens.vue'
import ui from '/~/core/ui'

export default {
  name: 'rec-perk',
  components: {
    BaseBack,
    BaseImage,
    BaseButton,
    RecognitionTokens,
    PerkRedeemed,
    PerkInfo,
    DetailsPlaceholder,
  },
  setup() {
    const route = useRoute()

    const { currency, myRecId, features, profile, isAvailablePerk } =
      useRecProfile()

    const {
      gotIt,
      init,
      loading,
      openRequestModal,
      perk,
      perkDate,
      perkId,
      perkRedeemDisabled,
      perkStatus,
      redeemed,
      redeemedStatus,
      status,
    } = usePerk()

    perkStatus.value = route.params.perkStatus
    perkId.value = route.params.perkId

    return {
      currency,
      features,
      gotIt,
      init,
      isAvailablePerk,
      loading,
      myRecId,
      openRequestModal,
      perk,
      perkDate,
      perkId,
      perkRedeemDisabled,
      perkStatus,
      profile,
      redeemed,
      redeemedStatus,
      status,
      ui,
    }
  },
  computed: {
    redeemText() {
      return this.isAvailablePerk
        ? 'To redeem this perk'
        : 'Redeemed on this perk'
    },
  },
  created() {
    if (ui.desktop) {
      this.init()
    } else {
      modal.show('rec-perk-details-mobile', {
        props: {
          perkId: this.perkId,
          perkStatus: this.perkStatus,
        },
      })
    }
  },
  methods: {
    close() {
      this.$router.push({
        name: 'rec-perks',
        params: {
          type: this.perkStatus,
        },
      })
    },
  },
}
</script>

<template>
  <div class="mx-auto flex w-full max-w-screen-md flex-col p-5 md:p-[30px]">
    <div v-if="!redeemed" class="flex h-full flex-col">
      <details-placeholder
        v-if="loading || !perk"
        :action="perkStatus === 'available'"
        :points="features.points"
        :under-img="perkStatus !== 'available'"
        entity="perk"
      />
      <div v-else>
        <div>
          <base-back
            :to="{
              name: 'rec-perks',
              query: {
                type: perkStatus,
              },
            }"
            class="mb-6"
          />
        </div>

        <div class="relative h-full">
          <div class="flex grow flex-col space-y-2.5">
            <div class="mb-[15px] flex items-center">
              <div class="flex flex-col items-start">
                <h2
                  class="mb-[5px] grid grid-cols-1 break-words pr-[15px] text-eonx-neutral-800"
                >
                  {{ perk.name }}
                </h2>

                <div class="flex justify-start">
                  <div v-if="status">
                    <span
                      :class="[
                        'inline-block rounded-3xl px-5 py-[5px] text-sm font-semibold capitalize',
                        status.theme,
                        status.textColor,
                      ]"
                    >
                      {{ status.label }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="ml-auto hidden p-px md:block">
                <base-button
                  v-if="isAvailablePerk"
                  :disabled="perkRedeemDisabled"
                  @click="openRequestModal"
                >
                  <span class="text-base">
                    {{
                      perk.isApprovalAutomatic ? 'Redeem' : 'Request approval'
                    }}
                  </span>
                </base-button>
              </div>
            </div>

            <div
              class="flex flex-col space-y-[30px] lg:flex-row lg:space-x-6 lg:space-y-0"
            >
              <div class="min-w-40 shrink-0 xl:min-w-64">
                <base-image :ratio="1" :src="perk.logo" />
                <div v-if="features.points && !isAvailablePerk">
                  <recognition-tokens
                    :nominal="perk.cost ? perk.cost : 0"
                    color="gold"
                    class="mb-2.5 mt-5 rounded-md py-2.5"
                  />

                  <div class="text-sm font-semibold text-eonx-neutral-600">
                    {{ redeemText }}
                  </div>
                </div>
              </div>

              <div class="flex-1 space-y-[15px] text-eonx-neutral-800">
                <h3 v-if="!isAvailablePerk">Details</h3>
                <div v-if="features.points && isAvailablePerk" class="flex">
                  <recognition-tokens
                    :nominal="perk.cost ? perk.cost : 0"
                    :label="` ${redeemText}`"
                    color="gold"
                    class="rounded-md px-6 py-2.5"
                  />
                </div>

                <p class="grid grid-cols-1 break-words">
                  {{ perk.description }}
                </p>
                <perk-info
                  :perk-status="perkStatus"
                  :stock-enabled="perk.stockEnabled"
                  :total-stock="perk.totalStock"
                  :created-at="perkDate(perk.createdAt)"
                  :owner="perk.owner"
                  :approved-at="perk.approvedAt && perkDate(perk.approvedAt)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="redeemed">
      <perk-redeemed
        :perk="perk"
        :redeemed-status="redeemedStatus"
        @submit="gotIt()"
      />
    </div>
  </div>
</template>
